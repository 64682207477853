@font-face {
     font-family: 'Helvetica Now Display Bold';
     src: local('HelveticaNowDisplay-Bold'),
       url('../assets/fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
   }
   
   @font-face {
     font-family: 'Helvetica Now Display Medium';
     src: local('HelveticaNowDisplay-Medium'),
       url('../assets/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
   }
   
   @font-face {
     font-family: 'Helvetica Now Display Regular';
     src: local('HelveticaNowDisplay-Regular'),
       url('../assets/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
   }
   
   @font-face {
     font-family: 'Aeonik Regular';
     src: local('Aeonik-Regular'),
       url('../assets/fonts/Aeonik-Regular.otf') format('opentype');
   }
   
   @font-face {
     font-family: 'Aeonik Medium';
     src: local('Aeonik-Medium'),
       url('../assets/fonts/Aeonik-Medium.otf') format('opentype');
   }
   
   @font-face {
     font-family: 'Aeonik Bold';
     src: local('Aeonik-Bold'),
       url('../assets/fonts/Aeonik-Bold.otf') format('opentype');
   }